<script setup>
import useClients from '@/hooks/useClients';
import ClientLayout from '@/layouts/ClientLayout.vue';
import { ref } from 'vue';
import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const { client, fetchClient, updateClient } = useClients();
const route = useRoute();
const toast = useToast();
const notes = ref("");

onBeforeMount(async () => {
    await fetchClient(route.params.id)
    if (client.value.notes) {
        notes.value = client.value.notes;
    }
})

const save = async () => {
    await updateClient(route.params.id, { notes: notes.value })
    toast.success("Notes updated successfully.")
}

</script>

<template>
    <ClientLayout :client="client">
        <v-card class="mx-4 px-4 pt-6">
            <div class="d-flex ga-4">
                <label> Notes:</label>
                <v-textarea
                    v-model="notes"
                    rows="13"
                    placeholder="Enter internal client notes...."
                    variant="outlined"
                    auto-grow
                    />
            </div>

            <div class="d-flex justify-end mb-5">
                <v-btn 
                    size="large" 
                    variant="flat" 
                    color="primary"
                    @click="save"
                    >
                    Save
                </v-btn>
            </div>
        </v-card>
    </ClientLayout>
</template>